/**
 * <p>
 *     This is a class with settings for the CognitoUser(s) and the
 *     functions they can do.
 * </p>
 *
 * @author Jens van den Berg <jens@studyportals.com>
 * @copyright © 2017 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

window.CognitoUser = {};

export class CognitoUser {
	/**
	 * Basic constructor + setting the CognitoUser from the StudyPortals-trck Cookie.
	 *
	 * @param username
	 * @param id_token
	 * @param refresh_token
	 * @param expires
	 * @param isEmployee
	 */
	constructor(id_token, refresh_token, expires) {
		this.username = this.retrieveValueFromIdTokenByPropertyName(id_token, 'cognito:username');
		this.email = this.username;
		this.id_token = id_token;
		this.refresh_token = refresh_token;
		this.expires = expires;

		const groups = this.retrieveValueFromIdTokenByPropertyName(id_token, 'cognito:groups');
		this.isEmployee = groups.includes('sp_employees');

		window.CognitoUser = this;
	}

	retrieveValueFromIdTokenByPropertyName(idToken, property) {
		const tokenParts = idToken.split('.');
		if (tokenParts.length < 2) {
			return '';
		}

		const base64Url = tokenParts[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split('')
				.map((c) => {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		const jwt = JSON.parse(jsonPayload);

		return jwt[property] ? jwt[property] : '';
	}
}
